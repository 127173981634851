import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'gatsby';

import { Layout } from '../common/layout';
import { Seo } from '../common/seo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 50,
      height: '66vh'
    },
    notFound: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(30),
      marginBottom: 20,
      textAlign: 'center'
    },
    description: {
      marginBottom: 20,
      textAlign: 'center'
    },
    link: {
      textDecoration: 'underline',
      color: 'darkmagenta'
    }
  }),
);

export const Page404 = () => {
  const classes = useStyles();
  return (
    <Layout>
      <Seo title='Page not found' />
      <Box
        className={classes.root}
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <Typography className={classes.notFound}>
          Page Not Found
        </Typography>
        <Typography className={classes.description}>
          Oops! The page you are looking for does not exist.
        </Typography>
        <Link to={"/"} className={classes.link}>
            Go back
        </Link>
      </Box>
    </Layout>
  )
}
